import React from 'react';
import './AppLoader.scss';
/* import { ReactComponent as LogoStrokeIcon } from 'assets/icons/loader/loader_stroke_logo.svg'; */
import { ReactComponent as LogoIcon } from 'assets/icons/loader/loader_logo.svg';
import { AnimatePresence, motion } from 'framer-motion';

const AppLoader = ({ showLoader = true }) => {
  const [visible, setVisible] = React.useState(showLoader);

  React.useEffect(() => {
    let timer;
    if (!showLoader) {
      timer = setTimeout(() => setVisible(false), 1500); // Ensure it shows for at least 1.5 seconds
    } else {
      setVisible(true);
    }
    return () => clearTimeout(timer);
  }, [showLoader]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          key="app-loader"
          className="full-screen-container"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
{/*           <div className="loader-container">
            <LogoStrokeIcon />
          </div> */}
          <div className="loader-container">
            <LogoIcon />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AppLoader;
