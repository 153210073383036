// action - state management
import { SET_SITE} from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const userReducer = (state, action) => {
    switch (action.type) {
        case SET_SITE: {
            const { setSite } = action.payload;
            return {
                ...state,
                setSite
            };
        }

        default: {
            return { ...state };
        }
    }
};

export default userReducer;
