import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

export const AppLoaderContext = createContext();

export const useAppLoader = () => useContext(AppLoaderContext);

export const AppLoaderProvider = ({ children }) => {
  const [isAppLoading, setIsAppLoading] = useState(true);
  const prevStateRef = useRef(null);
  
  const removeLoader = () => {
    if (window.removeLoader) {
      window.removeLoader();
    }
  };

  const insertLoader = () => {
    if (window.insertLoader) {
      window.insertLoader();
    }
  };

  useEffect(() => {
    if (isAppLoading === prevStateRef.current) {
      // no changes
      return;
    }

    if (isAppLoading === false) {
      removeLoader();
    } else if (isAppLoading === true && prevStateRef.current !== null) {
      insertLoader();
    }

    prevStateRef.current = isAppLoading;
  }, [isAppLoading]);

  return (
    <AppLoaderContext.Provider value={{ isAppLoading, setIsAppLoading }}>
      {children}
    </AppLoaderContext.Provider>
  );
};