import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import { getTenantPrefix } from './ProxyPathService';
import { getLocales } from './TranslationsService';

const getCurrentLng = async () => {
  // Check local storage language.
  let currentLng = localStorage.getItem('currentLng');

  // If there was not set language to currentLng, then use window.navigator.language.
  if (!currentLng) {
    // Browser window.navigator.language is formated as simplified locale code - 'de'.
    currentLng = window.navigator.language;

    // Get locales settings
    let localeFromDb = await getLocales()
      .then(({ data }) => {
        const locales = data.items;
        return locales.find((x) => x.key.toLowerCase().includes(currentLng.toLocaleLowerCase()))?.key;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });

    // Assign the found locale from DB in the right format (de-DE) or if not found set default 'en-GB'.
    currentLng = localeFromDb ?? 'en-GB';
    console.log('currentLng', currentLng);
    localStorage.setItem('currentLng', currentLng);
  }

  return currentLng;
};

const getTenantId = () => {
  let tenantId = getTenantPrefix();
  console.log('tenantId', tenantId);
  if (tenantId) {
    let result = tenantId + '/';
    return result;
  } else {
    return '';
  }
};

const initI18n = async () => {
  const tenantId = getTenantId();
  // Set default locale before settings check
  let currentLng = await getCurrentLng();

  i18n
    .use(initReactI18next)
    .use(Backend)
    // passes i18n down to react-i18next
    .init({
      interpolation: {
        escapeValue: false,
      },
      lng: currentLng,
      fallbackLng: currentLng,
      load: 'currentOnly',
      react: {
        wait: true,
      },
      backend: {
        loadPath: `/api/${tenantId}v1/Translations?Locale={{lng}}`,
        addPath: `/api/${tenantId}v1/Translations?Locale={{lng}}`,
        allowMultiLoading: false,
      },
    });
};

initI18n();

export default i18n;
