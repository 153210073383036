const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/usersites',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    firebase: {
        "apiKey": "AIzaSyAitnXgp6d7bH7kY3rQ9PnX5zNbZV-ZOqI",
        "authDomain": "softra-app-develop.firebaseapp.com",
        "projectId": "softra-app-develop",
        "storageBucket": "softra-app-develop.appspot.com",
        "messagingSenderId": "103296810224",
        "appId": "1:103296810224:web:8c3d52856849a00f525635",
        "measurementId": "G-BKQ9JT8ERK"
    },
    auth0: {
        client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
        domain: 'dev-w0-vxep3.us.auth0.com'
    },
    googleMap:{
        googleMapsApiKey:"AIzaSyCWLnV7b8vtIZouJFBDWHtM-rxZryoP4JE",
        mapId: '4e39653a8ff6f166',
        center: {
            lat: 54.71187898981936,
            lng: 25.291562838969767
        },
    },
    kevin: {
        clientId : 'a94eae56-0e96-4f77-98d7-64f2bec33072',
        clientSecret : '7382340a857b1db8e89c10920a022abf0dad58470cdda5274ce2680ee4158d22',
    },
    kevinSandbox: {
        clientId : 'b98096af-2d60-4b1f-b545-efee6247ee51',
        clientSecret : '00c303ca6cda874cdc32e80b67cad3feed23ca377ffb5bafef4c952c8ded92fb',
    }
};

export default config;
