import { axiosA } from './AuthService';

const getLocales = async () => {
  try {
    return await axiosA.get('Translations/locales');
  } catch (e) {
    throw new Error(e);
  }
};

const getTranslationsForEditing = async (localeKey) => {
  if (!localeKey) {
    localeKey = 'lt-LT';
  }
  try {
    return await axiosA.get(`Translations/forediting?Locale=${localeKey}`);
  } catch (e) {
    throw new Error(e);
  }
};

const putTranslations = async (translation) => {
  try {
    return await axiosA.put(`Translations/${translation.id}`, translation);
  } catch (e) {
    throw new Error(e);
  }
};

export { getLocales, getTranslationsForEditing, putTranslations };
