import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import './assets/styles/fonts.css';
import './utils/i18n';
import 'react-notifications/lib/notifications.css';
import * as serviceWorker from 'serviceWorker';
import { Provider } from 'react-redux';
import { store } from 'stores';
import { AppLoaderProvider } from 'contexts/AppLoaderContext';
import AppLoader from 'ui-component/AppLoader';

const App = lazy(() => import('components/app/App'));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<AppLoader />}>
      <AppLoaderProvider>
        <App />
      </AppLoaderProvider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
