const getTenantPrefix = () => {
  if(process.env.NODE_ENV === 'development'){
    return null;
  }
  let tenantId = localStorage.getItem('tenantId');
  return `${tenantId ? tenantId : ''}`;
};

const getHubPath = () => {
  const tenantPrefix = getTenantPrefix();
  const prfix = tenantPrefix && tenantPrefix !== '' ? tenantPrefix + '/' : '';
  return `/api/${prfix}dashboardHub`;
};

export { getHubPath, getTenantPrefix };
