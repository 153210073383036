import { combineReducers } from 'redux';
//import { persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';

// reducer import
import userReducer from './userReducer';
import customizationReducer from './customizationReducer';
//import snackbarReducer from './snackbarReducer';
//import cartReducer from './cartReducer';
//import kanbanReducer from './kanbanReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    activeUser: userReducer,
    customization: customizationReducer
   // snackbar: snackbarReducer,

   // kanban: kanbanReducer
});

export default reducer;
