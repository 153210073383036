const HOST = process.env.NODE_ENV === 'production' ? 'https://parkinger.softra.lt' : ''

const BASE_URL = `${HOST}/api/v1`

export default {
  HOST,
  BASE_URL,
  TOKEN_HEADER_KEY: 'Authorization',
  BEARER: 'Bearer',
  ACCESS_KEY: 'at_park',
  TITLE: 'Parkinger',
  SEARCH_DEBOUNCE: 500,
  // token expiration is 3600 for external idp
  // leaving 300 seconds for refresh
  RE_T_IF: 3300, // Refresh token if will expire in seconds
}
